import { Calendar, Clock, MapPin } from "lucide-react";
import { Link } from "@remix-run/react";
import { Image } from "~/components/Image";

export function EventCard({ event, orientation = "vertical" }) {
  const image = event?.thumbnail?.image || event?.hero?.image;
  const startDate = new Date(event.start_datetime).toLocaleDateString([], {
    weekday: "short",
    month: "long",
    day: "numeric",
  });

  const endDate = new Date(event.end_datetime).toLocaleDateString([], {
    weekday: "short",
    month: "long",
    day: "numeric",
  });

  const startTime = new Date(event.start_datetime).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  const endTime = new Date(event.end_datetime).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  const startMonth = new Date(event.start_datetime).toLocaleDateString([], {
    month: "short",
  });
  const startDay = new Date(event.start_datetime).toLocaleDateString([], {
    day: "2-digit",
  });

  const date = startDate === endDate ? startDate : `${startDate} - ${endDate}`;

  if (orientation === "horizontal") {
    return (
      <a
        className="flex gap-4 border border-x-0 md:border-x md:rounded-md hover:bg-gray-100 overflow-hidden"
        href={event.url}
        suppressHydrationWarning
      >
        {image && (
          <div className="aspect-[4/3] w-1/3 bg-theme-green-2 flex-shrink-0">
            <Image src={image.url} alt="" className="object-fill object-center h-full" />
          </div>
        )}
        {!image && (
          <div className="aspect-[4/3] w-1/3 bg-gray-500 flex-shrink-0">
            {/* date placeholder */}
            <div className="flex items-center justify-center h-full text-center">
              <div className="text-2xl md:text-4xl font-semibold text-gray-100">
                <span className="uppercase">{startMonth}</span>
                <span className="block text-4xl md:text-6xl">{startDay}</span>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col justify-center w-2/3 py-3 overflow-x-hidden">
          <div>
            <h3 className="text-lg font-semibold truncate" title={event.title}>
              {event.title}
            </h3>
            <div className="text-gray-700 grid gap-1 text-sm mt-1">
              <div className="flex items-center gap-1 flex-shrink-0">
                <Calendar className="w-4" />
                {date}
              </div>
              <div className="flex items-center gap-1 flex-shrink-0">
                <Clock className="w-4" />
                {startTime} - {endTime}
              </div>
              {/* address */}
              <div className="flex items-start gap-1">
                <MapPin className="w-4 flex-shrink-0" />
                <div className="text-gray-700 text-sm" dangerouslySetInnerHTML={{ __html: event.address }}></div>
              </div>
            </div>
          </div>

          {/* <a href={event.url} className="block text-primary hover:underline text-sm mt-2">
            View Details
          </a> */}
        </div>
      </a>
    );
  }

  return (
    <Link className="w-full overflow-hidden" to={event.url} role="link" aria-label={event.title}>
      {image && (
        <div className="aspect-[4/3] bg-gray-500">
          <Image src={image.url} alt="" className="object-cover h-full w-full" />
        </div>
      )}
      {!image && (
        <div className="aspect-[4/3] bg-gray-500">
          {/* date placeholder */}
          <div className="flex items-center justify-center h-full text-center">
            <div className="text-4xl font-semibold text-gray-100">
              <span className="uppercase">{startMonth}</span>
              <span className="block text-6xl">{startDay}</span>
            </div>
          </div>
        </div>
      )}
      <div className="pt-2">
        <h3 className="text-lg font-semibold truncate" title={event.title}>
          {event.title}
        </h3>
        {/* date and time from event.start_datetime */}
        <p className="text-gray-700 flex flex-wrap items-center gap-3 lg xl:gap-4 text-sm">
          <span className="flex items-center gap-1 flex-shrink-0">
            <Calendar className="w-4" />
            {date}
          </span>
          <span className="flex items-center gap-1 flex-shrink-0">
            <Clock className="w-4" />
            {startTime}
          </span>
        </p>

        {/* <div
          className={cn("mt-2", {
            "text-gray-100": hasDarkBackground,
            "text-gray-700": !hasDarkBackground,
          })}
          dangerouslySetInnerHTML={{ __html: description }}
        ></div> */}
      </div>
    </Link>
  );
}
